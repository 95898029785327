<template>
  <div>
    <div class="innerContainer">
      <section class="banner-container-nd ml-5">
        <div class="banner-content">
          <div class="row mt-5">
            <div class="col-12 d-block d-md-none mx-5">
              <multiselect
                v-model="selectedOption"
                :options="selectOptions"
                :multiple="false"
                placeholder="Select option"
                :label="'name'"
                @input="handleSelectChange"
                class="custom-multiselect"
              />
            </div>

            <div class=" cards p-0 mt-5 col-md-4 col-lg-2 d-none d-md-block">
              <div 
                v-for="(bar, index) in bars"
                :key="index"
                :class="{'active': activeIndex === index}"
                @click="setActive(index)"
              >
                <div class="p-3" :class="{'active-type': activeIndex === index}">
                  {{ bar.name }}
                </div>
                <div v-if="activeIndex === index && bar.categories" class="categories-container">
                  <div 
                    v-for="(category, catIndex) in bar.categories" 
                    :key="catIndex"
                    @click.stop="setCategoryActive(catIndex)"
                    :class="{'active-category': activeCategoryIndex === catIndex}"
                    class="category-item"
                  >
                    {{ category.name }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-8 col-lg-9 pt-0">
              <component :is="categoryComponent" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DevisTypesBARTH from "@/views/DevisTypesBARTH.vue";
import DevisTypesBAREN from "@/views/DevisTypesBAREN.vue";
import AllDevis from "@/views/AllDevis.vue";
import devisTypesLibre from "./devisTypesLibre.vue";

export default {
  components: {
    AllDevis,
    DevisTypesBARTH,
    DevisTypesBAREN,
    devisTypesLibre,
    Multiselect
  },
  data() {
    return {
      activeIndex: 0,
      activeCategoryIndex: null,
      categoryComponent: "AllDevis",
      selectedOption: null, // Option sélectionnée dans le multiselect
      bars: [
        {
          name: "All",
          component: "AllDevis",
        },
        {
          name: "Liber",
          component: "devisTypesLibre",
        },
        {
          name: "BAR",
          categories: [
            { name: "BAR-EN", url: "", component: "DevisTypesBAREN" },
            { name: "BAR-TH", url: "", component: "DevisTypesBARTH" },
          ],
        },
      ],
      selectOptions: [
        { name: "All", component: "AllDevis", default: true },
        { name: "Liber", component: "devisTypesLibre" },
        { name: "BAR-EN", component: "DevisTypesBAREN" },
        { name: "BAR-TH", component: "DevisTypesBARTH" },
      ],
    };
  },
  created() {
    // Initialiser selectedOption avec l'option par défaut
    const defaultOption = this.selectOptions.find(option => option.default) || this.selectOptions[0];
    this.selectedOption = defaultOption;
    this.categoryComponent = defaultOption.component;
  },
  methods: {
    setActive(index) {
      this.activeIndex = index;
      const selectedBar = this.bars[index];

      if (!selectedBar.categories) {
        // Si l'élément n'a pas de catégories, affiche directement son composant
        this.categoryComponent = selectedBar.component;
        this.activeCategoryIndex = null; // Réinitialiser la catégorie active
      } else {
        this.activeCategoryIndex = null;
        this.categoryComponent = this.bars[0].component; 
      }
    },
    setCategoryActive(index) {
      this.activeCategoryIndex = index;
      const category = this.bars[this.activeIndex]?.categories?.[index];
      if (category?.component) {
        this.categoryComponent = category.component;
      }
      if (category?.url) {
        window.open(category.url, "_blank");
      }
    },
    handleSelectChange(selected) {
      if (selected) {
        // Trouver l'option sélectionnée et mettre à jour le composant
        const selectedBar = this.selectOptions.find(option => option.name === selected.name);
        if (selectedBar) {
          this.categoryComponent = selectedBar.component;
          this.activeCategoryIndex = null;
        }
      }
    },
  },
};
</script>



  <style scoped>
  .custom-multiselect {
  width: 50% !important; /* Réduit la largeur à 50% */
}
  .innerContainer {
    margin-right: 0;
    padding: 15px 0px 15px;
    min-height: 100vh;
}


  .cards {
    background-color: #24316d;
    color: white;
    height: 30%;

  
  }
  
  .cards div {
    cursor: pointer;

  }
  
  .active-type {
    color: white;
    background-color: #3a4891;
    border-left: 5px solid red;
  }
  
  .categories-container {
   background-color: #24316d;
    color: white;
  }
  
  .category-item {
    cursor: default;
    color: lightblue;
    text-decoration: underline;
    padding: 20px;
    background-color: transparent;
   
  }
  
  
  .category-item.active-category {
    background-color: #7f90e9bd;
    border-left: 5px solid red;
    padding: 15px;
    color: white; 
  }
  </style>
  