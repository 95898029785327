<template>
  <div>
  <div class="inner-container">
    <section class="">
     
      <div class="">
     
      <div class="d-flex  mx-5">
        <b-form-group  >
  <b-form-checkbox v-model="selectedType" :checked="selectedType" name="selectedType" >
    Veuillez effectuer plusieurs travaux
  </b-form-checkbox>
</b-form-group>
      </div>
          
        <div class="cards-container">
          <v-card
            v-for="(card, index) in cards"
            :key="index"
            class="hover-card"
          >
            <v-checkbox
              v-if="selectedType === true"
              v-model="selectedCards"
              :value="card"
              color="success"
              class="checkbox"
            ></v-checkbox>

            <div
              class="text-center title-color my-3"
              @click="handleCardClick(card)"
            >
              {{ card.title }}
            </div>
          </v-card>
        </div>
      <div class="d-flex justify-content-end mt-4">
        <b-button
          v-if="selectedType === true"
         class="button-pre-style title-color  m-3"
          @click="handleCardClick('Type Complexe')"
        >
        confirme
        </b-button> 
      </div>
 
        
      </div>
    </section>
  </div>
  </div>
</template>
<script>
  import { mapGetters,mapActions } from "vuex";
  export default {
   
  data() {
    return {
      selectedType: false,
      types: [
        { text: "Complexe", value: "Complexe" },
      ],
      selectedCards: [],
      data: {
        brouillon: 1,
        type_devis: "",
        modele_id: "14",
      },
    cards: [
    { title: "BAR-TH-101", route: "/devis/devisTypes" },
      { title: "BAR-TH-112", route: "/devis/devisTypes" },
      { title: "BAR-TH-113", route: "/devis/devisTypes" },
      { title: "BAR-TH-171", route: "/devis/devisTypes" },
      { title: "BAR-TH-143", route: "/devis/devisTypes" },
      { title: "BAR-TH-127", route: "/devis/devisTypes" },
      { title: "BAR-TH-129", route: "/devis/devisTypes" },
      { title: "BAR-TH-137", route: "/devis/devisTypes" },
      { title: "BAR-TH-148", route: "/devis/devisTypes" },
      { title: "BAR-TH-173", route: "/devis/devisTypes" },
      { title: "BAR-TH-174", route: "/devis/devisTypes" },
      { title: "BAR-TH-175", route: "/devis/devisTypes" },
    ],
  };
},

computed: {
    ...mapGetters(["getTabs", "getOnlineUser", "getcreationEntreprise", "getdevisType"]),
    isSuperAdmin() {
      return this.getOnlineUser.role === "user.super-admin";
    },
    isEntrepriseAdmin() {
      return this.getOnlineUser.role === "user.entreprise-admin";
    },
    isCommercial() {
      return this.getOnlineUser.role === "user.commercial-entreprise";
    },
    isAssistant() {
      return this.getOnlineUser.role === "user.assistant";
    },
  },

  methods: {
    ...mapActions(["store_devis", "updateCreationEntreprise", "devisTypeAdd","addGroupe"]),

    async createDevis(typeDevis) {
    
        this.data.type_devis = typeDevis;
        this.data.entreprise_id = this.getOnlineUser.entreprise.id;
        const response = await this.store_devis(this.data);
        if (this.selectedCards.length > 0) {
          const groupes = this.selectedCards.map((card) => ({
  document_id: response.data?.id,
  type_group: card.title,
}));

for (const groupe of groupes) {
  await this.addGroupe(groupe); 
}
        } else {
          const groupes = 
            {
              document_id:response.data?.id,
              type_group:typeDevis,
            };
          await this.addGroupe(groupes)
        }

      
        this.id = response.data?.devis?.id;
        //emite le selectedCards
    this.$router.push(`/devis/update/${this.id}`);

     
    },
    handleTypeChange() {
      this.selectedCards = [];
    },
    handleCardClick(card) {
      this.createDevis(!this.selectedType ? card.title : card);
      this.updateCreationEntreprise(true);
    },
  },
};
</script>
<style lang="scss" scoped>
.button-pre-style {
background-color: #24316d !important;
box-shadow: none;
border-radius: 5px 5px 5px 5px;
color:white
}
.checkbox {
position: absolute;
top: 10px;
right: 10px;
margin: 0;
transform: scale(1.2);
}


.inner-container {
cursor:default;
padding: 0 !important;
width: 100%;
margin-top: 50px;
}

@media only screen and (min-width: 810px) {
.banner-container-nd {
  position: relative;
  height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  width: 100%;
}
}

.banner-content {
@media only screen and (max-width: 900px) {
  padding: 8px;
  width: 100%;
}
width: 100%;
text-align: center;

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // gap: 20px;
}

.v-card {
  flex: 1 1 calc(25% - 20px);
  max-width: calc(25% - 20px);
  margin: 10px;
  border-radius: 8px;
  background-color: #24316d;
  font-size: 16px;
  font-weight: 600;
  padding: 20px;
  position: relative;
}

@media only screen and (max-width: 810px) {
  .banner-container-nd {
    position: relative;
    height: calc(140vh - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    width: 100%;
  }
  .v-card {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 620px) {
  .banner-container-nd {
    position: relative;
    height: calc(200vh);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    width: 100%;
  }
  .v-card {
    flex: 1 1 calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
}
}

.hover-card {
color: white;
transition: box-shadow 0.3s ease;
}

.hover-card:hover {
box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
}

.card-link {
background-color: #24316d;
text-decoration: none;
color: white;
display: flex;
flex-direction: column;
align-items: center;
padding: 16px;
}
.title-color {
align-items: center;
color: white;
}
</style>